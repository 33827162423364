import { Link } from "gatsby"
import Img from "gatsby-image"
import * as React from "react"
import './Card.scss';

export const Card = ({post, title}) => {
  return (
    <li key={post.fields.slug}>
      <article
        className="card"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h2>
            <Link to={post.fields.slug} itemProp="url">
              <span itemProp="headline">{title}</span>
            </Link>
          </h2>
          <small>{post.frontmatter.date}</small>
        </header>
        {
          post.frontmatter.image &&
          <Img {...post.frontmatter.image.childImageSharp} />
        }
        <section>
          <p
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
            itemProp="description"
          />
          <Link to={post.fields.slug} itemProp="url">
            Pokračovat ve čtení
          </Link>
        </section>
      </article>
    </li>
  );
}